import PostLayout from 'components/PostLayout'
import { graphql } from 'gatsby'
import React from 'react'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import Layout from 'components/Layout'
import { Posts } from 'components/Blog'
import Pagination from 'components/Pagination'
import { NewsletterForm } from 'components/NewsletterForm'

const Events = ({
    data: {
        allEventsRecent: { edges: allEventsRecent },
    },
    pageContext: { numPages, currentPage, base },
}) => {
    return (
        <Layout>
            <SEO title={`All events`} />

            <PostLayout
                breadcrumb={[{ name: 'Events', url: '/webinars-events' }, { name: 'All' }]}
                article={false}
                title="Events"
                // menu={event}
                hideSidebar
                hideSurvey
            >
                <Posts
                    title="All events"
                    action={
                        <p className="m-0 font-semibold leading-none">
                            Page {currentPage} of {numPages}
                        </p>
                    }
                    posts={allEventsRecent?.slice(0, 4)}
                />
                <NewsletterForm />
                <Posts posts={allEventsRecent?.slice(4)} />
                <Pagination currentPage={currentPage} numPages={numPages} base={base} />
            </PostLayout>
        </Layout>
    )
}

export default Events

export const pageQuery = graphql`
    query ($skip: Int!, $limit: Int!) {
        allEventsRecent: allMdx(
            limit: $limit
            skip: $skip
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { isFuture: { ne: true }, fields: { slug: { regex: "/^/webinars-events/" } } }
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
    }
`
